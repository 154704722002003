body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ececec;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: white;
}
a:hover {
  color: #f2a365;
}

.about {
  height: 95vh;
}

.about-card {
  width: 40vw;
  margin-right: 2vw;
}

.about-img {
  height: 40em;
  width: auto;
}

.about-text {
  font-size: 1.2rem;
}

.contact {
  padding: 1rem;
  color: white;
  background-color: #30475e;
}

.footer {
  height: 5vh;
  background-color: #222831;
}

.font-bold {
  font-weight: bold;
}

.headshot-background {
  background-color: #f2a365;
  height: 100%;
  width: 50vw;
}

.navstyle {
  /* height: 10vh; */
  background-color: #222831;
}

.proj-card {
  margin: 1rem;
}

.proj-tech-logo {
  padding: 1px;
  height: 5vh;
}

.proj-text {
  width: 75rem;
  text-align: center;
  font-size: 1.2rem;
  /* font-weight: 400; */
  color: #30475e;
}

.proj-title {
  color: #30475e;
  font-weight: bold;
  font-size: 4vh;
}

@media (max-width: 800px) {
  .about {
    height: auto;
  }

  .about-card {
    width: 100vw;
  }

  .about-img {
    height: 30rem;
    width: auto;
  }

  .headshot-background {
    background-color: #f2a365;
    height: 100%;
    width: 100vw;
  }
}
